<template>
  <div class="newList">
    <div class="page_header_img">
      <!-- <Banner :bannerList="bannerList"></Banner> -->
      <img :src="bannerList[0].pic" class="bannerImg" />
    </div>
    <div class="page_body_con">
      <div class="margin-tb-35 flex-space-center">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
            <el-breadcrumb-item>{{ type == 1 ? "企业新闻" : "集团新闻" }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="page_body_con moment-row animation-slide-bottom">
      <el-row class="flex">
        <el-col :span="20" class="white_bg">
          <div v-show="type == 1">
            <div
              class="news_item flex"
              v-for="(o, index) in list"
              @click="bindDetail(o.id)"
              @mouseenter="selectchoose(index)"
              @mouseleave="outchoose(index)"
            >
              <div class="lt">
                <img :src="o.pic" />
                <!-- <span class="bigtime" v-if="activeIndex == index">{{
                  o.create_time | filterdataTime("mouthdate")
                }}</span>
                <span class="bigtime" v-else>{{
                  o.create_time | filterdataTime("date")
                }}</span>
                <span class="time" v-if="activeIndex == index">{{
                  o.create_time | filterdataTime("year")
                }}</span>
                <span class="time" v-else>{{
                  o.create_time | filterdataTime("yearmouth")
                }}</span> -->
              </div>
              <div class="rt ta-l">
                <h6 class="litle">
                  {{ o.title }}
                </h6>
                <p class="descTxt line3">
                  {{ o.desc }}
                </p>
                <p>发布者：中集智本 {{ o.create_time }}</p>
              </div>
            </div>
          </div>
          <div v-show="type == 2">
            <div
              class="news_item flex"
              v-for="(o, index) in list"
              @click="bindDetail(o.id)"
              @mouseenter="selectchoose(index)"
              @mouseleave="outchoose(index)"
            >
              <div class="lt">
                <!-- <span class="bigtime" v-if="activeIndex == index">{{
                  o.create_time | filterdataTime("mouthdate")
                }}</span>
                <span class="bigtime" v-else>{{
                  o.create_time | filterdataTime("date")
                }}</span>
                <span class="time" v-if="activeIndex == index">{{
                  o.create_time | filterdataTime("year")
                }}</span>
                <span class="time" v-else>{{
                  o.create_time | filterdataTime("yearmouth")
                }}</span> -->
                <img :src="o.pic" />
              </div>

              <div class="rt ta-l">
                <h6 class="litle">
                  {{ o.title }}
                </h6>
                <p class="descTxt">
                  {{ o.desc }}
                </p>
                <p>发布者：中集智本 {{ o.create_time }}</p>
              </div>
            </div>
          </div>
          <div class="page_body_con pagination ta-c">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[5, 10, 20, 50, 100]"
              :page-size="limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>

        <el-col :span="4" class="news_lt">
          <div class="page_title_name ta-l">
            <h3>新闻中心</h3>
            <span>PRODUCT CENTER</span>
          </div>
          <ul>
            <li :class="[type == 1 ? 'active' : '']" @click="bindNews(1)">
              企业新闻
            </li>
            <li :class="[type == 2 ? 'active' : '']" @click="bindNews(2)">
              集团新闻
            </li>
          </ul>
          <img :src="require('@/assets/images/news/newlist.png')" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SelectGroup from "@/components/SelectGroup.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    SelectGroup,
    Banner,
  },
  data() {
    return {
      type: 1,
      page: 1,
      limit: 5,
      total: 0,
      list: [],
      bannerList: [
        { src: "", pic: "" },
        // { src: require("@/assets/images/header/banner2.png") },
      ],
      activeIndex: "",
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path == "/news/list") {
          this.type = route.query.type == undefined ? 1 : route.query.type;
          this.page = 1;
          this.getNewList();
        }
      },
      immediate: true,
    },
  },
  filters: {
    //过滤器一：（使用时没有参数，即{{msg|filterTime}}）
    //日
    filterdataTime(acquaintance, type) {
      let timearr = acquaintance
        .replace(" ", ":")
        .replace(/\:/g, "-")
        .split("-");
      let nian = timearr[0];
      let yue = timearr[1];
      let ri = timearr[2];
      let shi = timearr[3];
      let fen = timearr[4];
      let miao = timearr[5];
      if (type == "date") {
        return ri;
      } else if (type == "year") {
        return nian;
      } else if (type == "yearmouth") {
        return nian + "/" + yue;
      } else if (type == "mouthdate") {
        return yue + "/" + ri;
      }
    },
  },
  activated() {
    // this.getBannerList();
    setTimeout(() => {
      this.bannerList = this.$store.state.bannerList.filter((item) => {
        let sonarr = item.type.split("|");
        return sonarr.includes("5");
      });
    }, 500);
  },
  methods: {
    selectchoose(e) {
      this.activeIndex = e;
    },
    outchoose(e) {
      this.activeIndex = "";
    },
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          this.bannerList = arr;
          // console.log(res.data[0].children)
        }
      });
    },
    bindDetail(e) {
      this.$router.push({
        path: "/news/detail",
        query: {
          id: e,
        },
      });
    },
    bindNews(e) {
      this.activeIndex = "";
      this.type = e;
      this.page = 1;
      this.getNewList();
    },
    bindchange(e) {
      console.log("父", e);
    },
    getNewList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: this.limit,
          page: this.page,
          type: 3,
          type_id: this.type,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.data.data;
          this.total = res.data.count;
          // console.log(res.data[0].children)
        }
      });
    },
    bindProductDetail(e) {
      console.log("go1");
      console.log("go", e);

      this.$router.push({
        path: "/product/productdetail",
        query: {
          //query是个配置项
          age: 20,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getNewList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getNewList();
    },
  },
};
</script>

<style lang="scss" scoped>
.newList {
  background: #fff;
}
.page_title > span {
  border: none;
}
.button {
  padding: 0;
  float: right;
}

.moment-row {
  text-align: center;
  // padding-top: 30px;
  // padding-bottom: 72px;
  padding: 0px 15px 30px;
  background: #ffffff;
  // overflow: hidden;
  // float: right;
  .title {
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    height: 42px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: left;
  }
  .news_lt {
    padding: 0 15px;
    margin: 0 0 30px 0;
    .title {
      h3 {
        width: 221px;
        height: 82px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        font-style: italic;
        color: #000000;
        line-height: 40px;
        margin-bottom: 5px;
      }
      span {
        width: 176px;
        height: 43px;
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: #000000;
        line-height: 46px;
        margin-top: 10px auto 30px;
      }
    }
    ul {
      margin: 30px auto 15px;
      background: #f4f4f4;
      li {
        height: 50px;
        width: 100%;
        background: #f4f4f4;
        color: #333;
        // margin: 0px 0px 15px 15px;
        line-height: 50px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
      }
      .active {
        background: #006acd;
        color: #fff;
      }
    }
    img {
      margin: 30px 0;
      width: 100%;
      height: 300px;
    }
  }
  .news_item {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    align-items: flex-start;
    cursor: pointer;
    img {
      display: inline-block;
      // width: 240px;
      // height: 120px;
      width: 100%;
      height: 100%;
    }
    .lt {
      width: 268px;
      height: 130px;
      // background: #006acd;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: relative;
      .bigtime {
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 50px;
        font-family: Roboto, BlinkMacSystemFont, "Helvetica Neue", Arial, "Microsoft Yahei",
          "WenQuanYi Micro Hei", "Pingfang SC", sans-serif;
        font-weight: 400;
        color: #ffffff;
      }
      .time {
        position: absolute;
        bottom: 15px;
        right: 15px;
        font-size: 24px;
        font-family: Roboto, BlinkMacSystemFont, "Helvetica Neue", Arial, "Microsoft Yahei",
          "WenQuanYi Micro Hei", "Pingfang SC", sans-serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .rt {
      display: flex;
      flex-direction: column;
      margin: 0px 15px;
      box-sizing: border-box;
      padding: 5px 0px;
      flex: 1;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        // line-height: 32px;
      }
      .descTxt {
        height: 68px;
      }
      p {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        margin-top: 5px;
      }
      p:last-child {
        margin-top: 10px;
      }
    }
  }
  .news_item:hover {
    background: #f2f2f2;
    // .rt {
    //   background: #f2f2f2;
    // }
  }
}
.moment-rt {
  padding: 0px 15px 30px 15px;
  .page_title {
    h6 {
      // margin: 30px 0 0px 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-style: italic;
      color: #000000;
    }
    span {
      // margin-left: 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #000000;
      line-height: 60px;
      height: 60px;
      display: inline-block;
    }
  }
  .hort {
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 46px;
      height: 46px;
    }
    .hort_list {
      display: flex;
      flex-direction: column;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        height: 32px;
      }
      .gray_bg {
        padding: 15px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
